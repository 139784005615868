// SummaryTable.js
import React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { formatCurrency } from 'utils/formatCurrency';

const SummaryTable = ({ summaryData }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Units</TableCell>
            <TableCell>Front End Gross</TableCell>
            <TableCell>Product Gross</TableCell>
            <TableCell>Reserve</TableCell>
            <TableCell>Back Gross</TableCell>
            <TableCell>Total Gross</TableCell>
            <TableCell>Commission</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(summaryData).map(([type, data]) => (
            <TableRow key={type}>
              <TableCell>{type}</TableCell>
              <TableCell>{data.units}</TableCell>
              <TableCell>{formatCurrency(data.totalFrontGross)}</TableCell>
              <TableCell>{formatCurrency(data.totalProductGross)}</TableCell>
              <TableCell>{formatCurrency(data.totalFIIncome)}</TableCell>
              <TableCell>{formatCurrency(data.totalBackGross)}</TableCell>
              <TableCell>{formatCurrency(data.totalTotalGross)}</TableCell>
              <TableCell>{formatCurrency(data.totalCommission)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SummaryTable;
