import React, { useEffect, useState, useMemo } from 'react';
import { useGridApiRef, DataGridPro } from '@mui/x-data-grid-pro';
import { startOfMonth, endOfMonth, getDate, getDaysInMonth } from 'date-fns';
import axios from 'axios';
import {
  Box,
  Stack,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  Snackbar,
  Alert,
  IconButton,
  Switch,
} from '@mui/material';
import { yellow, grey } from '@mui/material/colors';
import columns from './columns';
import { formatCurrency } from 'utils/numberUtils';
import { API_BASE_URL } from 'config';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalendarDateRangePicker from 'components/DateRangePicker';

function SalesManagerCommission() {
  const [data, setData] = useState([]);
  const [totalGrossProfit, setTotalGrossProfit] = useState(0);
  const [dealStatus, setDealStatus] = useState('Blended');
  const [currentMonth, setCurrentMonth] = React.useState(dayjs().startOf('month'));
  const [dateRange, setDateRange] = React.useState([currentMonth.startOf('month'), currentMonth.endOf('month')]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = React.useState(null);
  const [isMonthNavigation, setIsMonthNavigation] = React.useState(true);

  const apiRef = useGridApiRef();

  const handleCloseSnackbar = () => setSnackbar(null);

  const calculateGrossPace = (gross) => {
    const today = new Date();
    return (gross / getDate(today)) * getDaysInMonth(today);
  };

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const { id, totalPhone } = newRow;
      const currentYearMonth = dateRange[0].format('YYYY-MM');
      const editedPhoneRank = {
        name: id, // The ID of the row
        yearMonth: currentYearMonth, // Use the dynamically set current year and month
        value: totalPhone, // The updated phone rank value
      };
      console.log('editedPhoneRank', editedPhoneRank);

      // Send a POST request to save phone edits
      const response = await axios.post(
        `${API_BASE_URL}/api/commissions/savePhoneEdits/606beccd6bab7103f8c3f84c`, // Need to un hardcode this....
        [editedPhoneRank],
      );

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      setSnackbar({
        children: 'Phone Rank successfully saved',
        severity: 'success',
      });

      return newRow;
    },
    [dateRange],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/commissions/606beccd6bab7103f8c3f84c`, // Need to un hardcode this....
          {
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
              dealStatus,
            },
          },
        );

        setTotalGrossProfit(response.data[0]?.dealerProfit ?? 0);
        setData(response.data ?? []);
      } catch (error) {
        console.error('Error fetching deals:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dateRange, dealStatus]);

  const checkIfSameMonth = (range) => {
    const today = new Date();
    return range[0] >= startOfMonth(today) && range[1] <= endOfMonth(today);
  };

  const columnGroupingModel = useMemo(
    () => [
      {
        groupId: 'Units',
        children: [
          { field: 'totalNew' },
          { field: 'totalUsed' },
          { field: 'totalUnits' },
          { field: 'unitPace' },
        ],
      },
      {
        groupId: 'Gross',
        children: [
          { field: 'totalFrontGross' },
          { field: 'totalBackGross' },
          { field: 'totalGrossProfit' },
          { field: 'totalGrossPVR' },
        ],
      },
      {
        groupId: 'Phone',
        children: [{ field: 'totalPhone' }],
      },
      {
        groupId: 'Rank Categories',
        children: [
          { field: 'totalUsed_rank' },
          { field: 'totalUnits_rank' },
          { field: 'totalBackGross_rank' },
          { field: 'totalGrossProfit_rank' },
          { field: 'totalGrossPVR_rank' },
          { field: 'realtimePhone_rank' },
        ],
      },
      {
        groupId: 'Total',
        children: [{ field: 'updated_rank' }],
      },
      {
        groupId: 'Estimated Commission',
        children: [
          { field: 'Percentage' },
          { field: 'Amount' },
          { field: 'Pacing' },
        ],
      },
    ],
    [],
  );

  const handlePreviousMonth = () => {
    setCurrentMonth((prev) => prev.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prev) => prev.add(1, 'month'));
  };

  useEffect(() => {
    setDateRange([currentMonth.startOf('month'), currentMonth.endOf('month')]);
  }, [currentMonth]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper>
        <Stack direction="row" spacing={2} padding={2} alignItems="center">
          <FormControl size="small">
            <InputLabel sx={{
              marginBottom: 1,
            }}>{isMonthNavigation ? "Month" : null}</InputLabel>
            {isMonthNavigation ? (
              <Select
                value={currentMonth.format('YYYY-MM')}
                label="Month"
                onChange={(e) => {
                  const [year, month] = e.target.value.split('-');
                  const newDate = currentMonth.year(parseInt(year)).month(parseInt(month) - 1);
                  handleNextMonth(newDate);
                }}
                endAdornment={
                  <Box sx={{ display: 'flex', mr: 2 }}>
                    <IconButton size="small" onClick={() => handlePreviousMonth()}>
                      <ChevronLeftIcon />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleNextMonth()}>
                      <ChevronRightIcon />
                    </IconButton>
                  </Box>
                }
              >
                {[...Array(12)].map((_, i) => {
                  const monthDate = currentMonth.clone().subtract(6, 'month').add(i, 'month');
                  return (
                    <MenuItem key={i} value={monthDate.format('YYYY-MM')}>
                      {monthDate.format('MMM YYYY')}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              <CalendarDateRangePicker
                  dateRange={dateRange}
                  onDateRangeChange={setDateRange}
              />
            )}
          </FormControl>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="deal-status-label">Deal Status</InputLabel>
              <Select
                labelId="deal-status-label"
                id="deal-status-select"
                value={dealStatus}
                label="Deal Status"
                onChange={(e) => setDealStatus(e.target.value)}
              >
                <MenuItem value="Blended">Blended</MenuItem>
                <MenuItem value="F">Finalized</MenuItem>
                <MenuItem value="B">Non-Finalized</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* Add an export button here */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => apiRef.current.exportDataAsCsv()}
          >
            Export
          </Button>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Typography variant="body2" sx={{ mr: 1 }}>Use Month Navigation:</Typography>
            <Switch
              size="small"
              checked={isMonthNavigation}
              onChange={() => setIsMonthNavigation((prev) => !prev)}
            />
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box>
            <Typography variant="h6">
              Total Gross Profit: {formatCurrency(totalGrossProfit)}
            </Typography>
            {checkIfSameMonth(dateRange) && (
              <Typography variant="subtitle1">
                Pacing: {formatCurrency(calculateGrossPace(totalGrossProfit))}
              </Typography>
            )}
          </Box>
        </Stack>
        <Box
          sx={{
            '& .phone-cell': {
              backgroundColor: yellow[100],
              color: 'black',
            },
            '& .rank-cell-light-bold': {
              backgroundColor: grey[100],
              fontWeight: 'bold',
              color: 'black',
            },
            '& .rank-cell-medium-bold': {
              backgroundColor: grey[200],
              fontWeight: 'bold',
              color: 'black',
            },
            '& .rank-cell-medium': {
              backgroundColor: grey[200],
              color: 'black',
            },
          }}
        >
          <DataGridPro
            apiRef={apiRef}
            sx={{
              borderRadius: 0,
              border: 0,
              borderTop: 1,
              borderColor: 'divider',
            }}
            autoHeight
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            loading={isLoading}
            density="compact"
            columnVisibilityModel={{ Pacing: checkIfSameMonth(dateRange) }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updated_rank', sort: 'asc' }],
              },
            }}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            hideFooter
          />
        </Box>
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Paper>
    </LocalizationProvider>
  );
}

export default SalesManagerCommission;
