import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Typography,
  Stack,
  LinearProgress,
  Paper,
  Divider,
  CircularProgress,
} from '@mui/material';
import { red, green, orange, yellow } from '@mui/material/colors';
import dayjs from 'dayjs';

const NEW_VEHICLES_GOAL = 60;
const USED_VEHICLES_GOAL = 35;

const calculatePace = (value) => {
  const today = dayjs();
  const startOfMonth = today.startOf('month');
  const totalDaysInMonth = today.daysInMonth();
  const daysElapsed = today.diff(startOfMonth, 'day');
  const pace = (value / daysElapsed) * totalDaysInMonth;
  return Math.round(pace);
};

const PaceReport = ({ data, loading }) => {
  const { isAuthenticated } = useAuth0();

  const ignoreIndividualGoals = [
    'NAVA,ALFREDO',
    'CALZADILLAS,GENARO H',
    'TARROZA,BIEN',
  ];

  const ignoreSalesManager = [
    'NAVA,ALFREDO', // Sales manager to ignore
    'BAHROOS,KABIR', // Sales manager to ignore
    'KHAN,SAIF', // Sales manager to ignore
    'HOUSE', // Sales manager to ignore
  ];

  const filteredData = data.filter((item) => {
    const dealCreditArray = [
      { name: item.CRMSP1Name, credit: item.CRMSaleCreditSP1 || 0 },
      { name: item.CRMSP2Name, credit: item.CRMSaleCreditSP2 || 0 },
      { name: item.CRMSP3Name, credit: item.CRMSaleCreditSP3 || 0 },
    ];

    const filteredDealCreditArray = dealCreditArray.filter(
      (dealCredit) => !ignoreIndividualGoals.includes(dealCredit.name),
    );

    const totalSalesCredit = filteredDealCreditArray.reduce(
      (acc, dealCredit) => acc + parseFloat(dealCredit.credit),
      0,
    );

    item.totalSalesCredit = totalSalesCredit;
    return !ignoreSalesManager.includes(item.CRMSalesMgrName);
  });

  const employeeData = filteredData
    .filter(
      (item) =>
        (item.FiWipStatusCode === 'F' || item.FiWipStatusCode === 'B') &&
        item.DealType !== 'Wholesale',
    )
    .reduce((acc, row) => {
      if (row.CRMSalesMgrName && row.CRMSalesMgrName.trim() !== '') {
        if (!acc[row.CRMSalesMgrName]) {
          acc[row.CRMSalesMgrName] = {
            name: row.CRMSalesMgrName,
            NewVehicles: 0,
            UsedVehicles: 0,
          };
        }

        acc[row.CRMSalesMgrName].NewVehicles +=
          row.FIDealType === 'New'
            ? parseFloat(row.totalSalesCredit) / 1000
            : 0;
        acc[row.CRMSalesMgrName].UsedVehicles +=
          row.FIDealType === 'Used'
            ? parseFloat(row.totalSalesCredit) / 1000
            : 0;
      }
      return acc;
    }, {});

  const groupedDataArray = Object.values(employeeData);

  // Sort the data by total sales credit
  groupedDataArray.sort(
    (a, b) => b.NewVehicles + b.UsedVehicles - (a.NewVehicles + a.UsedVehicles),
  );

  const updatedData = groupedDataArray.map((row, index) => ({
    id: index,
    ...row,
    NewVehiclesPace: calculatePace(row.NewVehicles),
    NewVehiclesGoal: NEW_VEHICLES_GOAL,
    UsedVehiclesPace: calculatePace(row.UsedVehicles),
    UsedVehiclesGoal: USED_VEHICLES_GOAL,
  }));

  const getColor = (pace, goal) => {
    if (pace >= goal) {
      return green[500];
    } else if (pace >= goal * 0.8) {
      return yellow[500];
    } else if (pace >= goal * 0.6) {
      return orange[500];
    } else {
      return red[500];
    }
  };

  const getLighterColor = (color) => {
    switch (color) {
      case green[500]:
        return green[200];
      case yellow[500]:
        return yellow[200];
      case orange[500]:
        return orange[200];
      case red[500]:
        return red[200];
      default:
        return color;
    }
  };

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          marginBottom={2}
        >
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            Sales Manager Pacing Report
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            justifyContent="flex-end"
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  backgroundColor: green[500],
                  marginRight: 1,
                }}
              />
              <Typography variant="caption">Pace ≥ Goal</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  backgroundColor: yellow[500],
                  marginRight: 1,
                }}
              />
              <Typography variant="caption">Pace ≥ 80% Goal</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  backgroundColor: orange[500],
                  marginRight: 1,
                }}
              />
              <Typography variant="caption">Pace ≥ 60% Goal</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  backgroundColor: red[500],
                  marginRight: 1,
                }}
              />
              <Typography variant="caption">Pace &lt; 60% Goal</Typography>
            </Box>
          </Stack>
        </Stack>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <Stack spacing={2}>
            {updatedData.map((item) => (
              <Box key={item.id}>
                <Typography variant="body2">{item.name}</Typography>
                <Divider />
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                  mt={2}
                >
                  <Box sx={{ width: '100%' }}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="button">New</Typography>
                      <Typography variant="button">
                        {item.NewVehicles.toFixed(2)} sold - pacing{' '}
                        {item.NewVehiclesPace.toFixed(2)} ({NEW_VEHICLES_GOAL}{' '}
                        goal)
                      </Typography>
                    </Stack>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                          variant="buffer"
                          value={(item.NewVehicles / NEW_VEHICLES_GOAL) * 100}
                          valueBuffer={
                            (item.NewVehiclesPace / NEW_VEHICLES_GOAL) * 100
                          }
                          sx={{
                            '& .MuiLinearProgress-bar1Buffer': {
                              backgroundColor: getColor(
                                item.NewVehiclesPace,
                                NEW_VEHICLES_GOAL,
                              ),
                            },
                            '& .MuiLinearProgress-bar2Buffer': {
                              backgroundColor: getLighterColor(
                                getColor(
                                  item.NewVehiclesPace,
                                  NEW_VEHICLES_GOAL,
                                ),
                              ),
                            },
                            '& .MuiLinearProgress-dashed': {
                              animation: 'none',
                              backgroundImage: `radial-gradient(${getColor(
                                item.NewVehiclesPace,
                                NEW_VEHICLES_GOAL,
                              )} 0%, ${getColor(
                                item.NewVehiclesPace,
                                NEW_VEHICLES_GOAL,
                              )} 16%, transparent 42%) !important`,
                            },
                          }}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >{`${Math.round(
                          (item.NewVehicles / NEW_VEHICLES_GOAL) * 100,
                        )}%`}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="button">Used</Typography>
                      <Typography variant="button">
                        {item.UsedVehicles.toFixed(2)} sold - pacing{' '}
                        {item.UsedVehiclesPace.toFixed(2)} ({USED_VEHICLES_GOAL}{' '}
                        goal)
                      </Typography>
                    </Stack>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                          variant="buffer"
                          value={(item.UsedVehicles / USED_VEHICLES_GOAL) * 100}
                          valueBuffer={
                            (item.UsedVehiclesPace / USED_VEHICLES_GOAL) * 100
                          }
                          sx={{
                            '& .MuiLinearProgress-bar1Buffer': {
                              backgroundColor: getColor(
                                item.UsedVehiclesPace,
                                USED_VEHICLES_GOAL,
                              ),
                            },
                            '& .MuiLinearProgress-bar2Buffer': {
                              backgroundColor: getLighterColor(
                                getColor(
                                  item.UsedVehiclesPace,
                                  USED_VEHICLES_GOAL,
                                ),
                              ),
                            },
                            '& .MuiLinearProgress-dashed': {
                              animation: 'none',
                              backgroundImage: `radial-gradient(${getColor(
                                item.UsedVehiclesPace,
                                USED_VEHICLES_GOAL,
                              )} 0%, ${getColor(
                                item.UsedVehiclesPace,
                                USED_VEHICLES_GOAL,
                              )} 16%, transparent 42%) !important`,
                            },
                          }}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >{`${Math.round(
                          (item.UsedVehicles / USED_VEHICLES_GOAL) * 100,
                        )}%`}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            ))}
          </Stack>
        )}
      </Paper>
    )
  );
};

export default PaceReport;
