import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import {
  Button,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GlobalStyles } from '@mui/material';

import {
  DataGridPremium,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import SummaryTable from './SummaryTable';
import DetailPanelContent from './DetailPanelContent';
import Filters from './Filters';

import { API_BASE_URL } from 'config';
import axios from 'axios';

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

const deal_types = ['All', 'New', 'Used', 'Other'];
const deal_status = ['B', 'F', 'I', 'P', 'C', 'U'];
const employee_types = [
  { label: 'Salesperson', value: 'salesperson' },
  { label: 'Finance Manager', value: 'financemanager' },
  { label: 'Sales Manager', value: 'salesmanager' },
  { label: 'Closer', value: 'closer' },
];

const Commissions = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [gridData, setGridData] = React.useState([]);
  const [summaryData, setSummaryData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [currentMonth, setCurrentMonth] = React.useState(dayjs().startOf('month'));
  const [dateRange, setDateRange] = React.useState([currentMonth.startOf('month'), currentMonth.endOf('month')]);
  const [dealType, setDealType] = React.useState('All');
  const [dealStatus, setDealStatus] = React.useState(['B', 'F']);
  const [employeeType, setEmployeeType] = React.useState('salesperson');
  const [pinnedRow, setPinnedRow] = React.useState([]);
  const [isMonthNavigation, setIsMonthNavigation] = React.useState(true);
  const [expandedRowIds, setExpandedRowIds] = React.useState([]);

  const defaultMainColumnVisibilityModel = React.useMemo(() => ({
    TotalFrontGross: true,
    PVRFrontGross: false,
    TotalProductGross: true,
    PVRProductGross: false,
    TotalFIIncome: true,
    PVRFIIncome: false,
    TotalBackGross: true,
    PVRBackGross: false,
    TotalGross: true,
    PVRTotalGross: false,
  }), []);

  const defaultColumnVisibilityModel = React.useMemo(() => ({
    CRMSP1Name: false,
    CRMSaleCreditSP1: false,
    CRMSP2Name: false,
    CRMSaleCreditSP2: false,
    CRMSP3Name: false,
    CRMSaleCreditSP3: false,
    CRMClosingMgrName: false,
    CRMSalesMgrName: false,
    CRMFIMgrName: false,
    VIN: false,
  }), []);

  const [mainColumnVisibilityModel, setMainColumnVisibilityModel] = React.useState(defaultMainColumnVisibilityModel);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(defaultColumnVisibilityModel);

  useEffect(() => {
    if (employeeType === 'salesperson') {
      setMainColumnVisibilityModel({
        ...defaultMainColumnVisibilityModel,
      });
      setColumnVisibilityModel({
        ...defaultColumnVisibilityModel,
        Products: false,
        BackGross: false,
        ProductGross: false,
        FIIncome: false,
        TotalGross: false,
      });
    } else if (employeeType === 'financemanager') {
      setMainColumnVisibilityModel({
        TotalFrontGross: false,
        PVRFrontGross: false,
        TotalGross: false,
        PVRTotalGross: false,
      });

      setColumnVisibilityModel({
        ...defaultColumnVisibilityModel,
        PayableFrontGross: false,
        PayableBackGross: false,
        FrontGross: false,
        CommGross: false,
        TotalGross: false,
        BonusCommission: false,
      });
    } else if (employeeType === 'salesmanager') {
      setMainColumnVisibilityModel({
        ...defaultMainColumnVisibilityModel,
        TotalCommission: false,
        TotalBonus: false,
      });
      setColumnVisibilityModel({
        ...defaultColumnVisibilityModel,
        PayableFrontGross: false,
        PayableBackGross: false,
        primaryEmployeeCommission: false,
        CalculatedCommission: false,
        BonusCommission: false,
      });
    } else if (employeeType === 'closer') {
      setMainColumnVisibilityModel({
        ...defaultMainColumnVisibilityModel,
      });
      setColumnVisibilityModel({
        ...defaultColumnVisibilityModel,
      });
    }
  }, [employeeType, defaultMainColumnVisibilityModel, defaultColumnVisibilityModel]);

  const toggleExpandAll = () => {
    setExpandedRowIds((prevExpandedRowIds) => {
      if (prevExpandedRowIds.length === gridData.length) {
        return [];
      } else {
        return gridData.map((row) => row.EmployeeID);
      }
    });
  };

  const handlePreviousMonth = () => {
    setCurrentMonth((prev) => prev.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prev) => prev.add(1, 'month'));
  };

  useEffect(() => {
    setDateRange([currentMonth.startOf('month'), currentMonth.endOf('month')]);
  }, [currentMonth]);

  const columns = [
    { field: 'EmployeeID', headerName: 'Employee No.', minWidth: 100, flex: 1 },
    {
      field: 'EmployeeName',
      headerName: 'Employee Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'PayplanCategory',
      headerName: 'Plan Type',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'TotalDealCredit',
      headerName: 'Deal Credit',
      minWidth: 70,
      flex: 1,
    },
    {
      field: 'TotalFrontGross',
      headerName: 'Front Gross',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'PVRFrontGross',
      headerName: 'PVR FEG',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'TotalProductGross',
      headerName: 'Product Gross',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'PVRProductGross',
      headerName: 'PVR PG',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'TotalFIIncome',
      headerName: 'Reserve',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'PVRFIIncome',
      headerName: 'PVR Reserve',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'TotalBackGross',
      headerName: 'Back Gross',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'PVRBackGross',
      headerName: 'PVR BG',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'TotalGross',
      headerName: 'Total Gross',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'PVRTotalGross',
      headerName: 'PVR TG',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'TotalCommission',
      headerName: 'Commission',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'CalculatedCommission',
      headerName: 'Audit',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
    {
      field: 'TotalBonus',
      headerName: 'Bonus',
      minWidth: 115,
      flex: 1,
      valueFormatter: ( value ) => formatCurrency(value),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/api/deals/${user.dealerid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
              dealType,
              statusCode: dealStatus.join(','),
              employeeType: employeeType,
            },
          }
        );
        const summary = response.data.summary;
        const details = response.data.details;

        const summaryRow = {
          EmployeeID: 'Summary',
          EmployeeName: '',
          PayplanCategory: '',
          TotalDealCredit: details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalFrontGross: details.reduce((acc, curr) => acc + curr.TotalFrontGross, 0),
          PVRFrontGross: details.reduce((acc, curr) => acc + curr.TotalFrontGross, 0) / details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalProductGross: details.reduce((acc, curr) => acc + curr.TotalProductGross, 0),
          PVRProductGross: details.reduce((acc, curr) => acc + curr.TotalProductGross, 0) / details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalFIIncome: details.reduce((acc, curr) => acc + curr.TotalFIIncome, 0),
          PVRFIIncome: details.reduce((acc, curr) => acc + curr.TotalFIIncome, 0) / details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalBackGross: details.reduce((acc, curr) => acc + curr.TotalBackGross, 0),
          PVRBackGross: details.reduce((acc, curr) => acc + curr.TotalBackGross, 0) / details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalGross: details.reduce((acc, curr) => acc + curr.TotalGross, 0),
          PVRTotalGross: details.reduce((acc, curr) => acc + curr.TotalGross, 0) / details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalCommission: details.reduce((acc, curr) => acc + curr.TotalCommission, 0),
          CalculatedCommission: details.reduce((acc, curr) => acc + curr.CalculatedCommission, 0),
          TotalBonus: details.reduce((acc, curr) => acc + curr.TotalBonus, 0),
          Sales: [],
        };

        setPinnedRow([summaryRow]);
        setGridData(details);
        setSummaryData(summary);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, [dateRange, dealType, dealStatus, employeeType, user, getAccessTokenSilently]);

  const getDetailPanelContent = (params) => (
    <DetailPanelContent
      salesData={params.row ? params.row.Sales : []}
      columnVisibilityModel={columnVisibilityModel}
      setColumnVisibilityModel={setColumnVisibilityModel}
    />
  );

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        paddingBottom: 2,
        paddingX: 2,
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        variant="text"
        size="small"
        startIcon={expandedRowIds.length === gridData.length ? <RemoveIcon /> : <AddIcon />}
        onClick={toggleExpandAll}
      >
        {expandedRowIds.length === gridData.length ? 'Collapse All' : 'Expand All'}
      </Button>
      <div style={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );

  return (
    isAuthenticated && (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalStyles
          styles={{
            '.rowHighlight': {
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
            },
          }}
        />
        <Box>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="button">Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Filters
                isMonthNavigation={isMonthNavigation}
                setIsMonthNavigation={setIsMonthNavigation}
                currentMonth={currentMonth}
                handlePreviousMonth={handlePreviousMonth}
                handleNextMonth={handleNextMonth}
                dateRange={dateRange}
                setDateRange={setDateRange}
                dealType={dealType}
                setDealType={setDealType}
                dealStatus={dealStatus}
                setDealStatus={setDealStatus}
                employeeType={employeeType}
                setEmployeeType={setEmployeeType}
                deal_types={deal_types}
                deal_status={deal_status}
                employee_types={employee_types}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1b-content"
              id="panel1b-header"
            >
              <Typography variant="button">Summary</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingX: 0 }}>
              <SummaryTable summaryData={summaryData} />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <Typography variant="button">Results</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingX: 0,
                paddingBottom: 0,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <DataGridPremium
                  sx={{ border: 'none' }}
                  density="compact"
                  getRowId={(row) => row.EmployeeID}
                  rows={gridData || []}
                  rowThreshold={1}
                  pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                  pinnedRows={{ top: pinnedRow }}
                  columns={columns}
                  columnVisibilityModel={mainColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) => setMainColumnVisibilityModel(newModel)}
                  autoHeight
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={() => 'auto'}
                  detailPanelExpandedRowIds={expandedRowIds}
                  onDetailPanelExpandedRowIdsChange={(newExpandedRowIds) => setExpandedRowIds(newExpandedRowIds)}
                  loading={loading}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </LocalizationProvider>
    )
  );
};

export default Commissions;
