import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  Switch,
  Grid,
  IconButton,
  ListItemText,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalendarDateRangePicker from 'components/DateRangePicker';

const Filters = ({
  isMonthNavigation,
  setIsMonthNavigation,
  currentMonth,
  handlePreviousMonth,
  handleNextMonth,
  dateRange,
  setDateRange,
  dealType,
  setDealType,
  dealStatus,
  setDealStatus,
  employeeType,
  setEmployeeType,
  deal_types,
  deal_status,
  employee_types,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Typography variant="body2" sx={{ mr: 1 }}>Use Month Navigation:</Typography>
              <Switch
                size="small"
                checked={isMonthNavigation}
                onChange={() => setIsMonthNavigation((prev) => !prev)}
              />
            </Box>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel sx={{
                marginBottom: 1,
              }}>{isMonthNavigation ? "Month" : null}</InputLabel>
              {isMonthNavigation ? (
                <Select
                  value={currentMonth.format('YYYY-MM')}
                  label="Month"
                  onChange={(e) => {
                    const [year, month] = e.target.value.split('-');
                    const newDate = currentMonth.year(parseInt(year)).month(parseInt(month) - 1);
                    handleNextMonth(newDate);
                  }}
                  endAdornment={
                    <Box sx={{ display: 'flex', mr: 2 }}>
                      <IconButton size="small" onClick={() => handlePreviousMonth()}>
                        <ChevronLeftIcon />
                      </IconButton>
                      <IconButton size="small" onClick={() => handleNextMonth()}>
                        <ChevronRightIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  {[...Array(12)].map((_, i) => {
                    const monthDate = currentMonth.clone().subtract(6, 'month').add(i, 'month');
                    return (
                      <MenuItem key={i} value={monthDate.format('YYYY-MM')}>
                        {monthDate.format('MMM YYYY')}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                <CalendarDateRangePicker
                    dateRange={dateRange}
                    onDateRangeChange={setDateRange}
                />
              )}
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Vehicle Type</InputLabel>
              <Select
                value={dealType}
                onChange={(e) => setDealType(e.target.value)}
                label="Vehicle Type"
              >
                {deal_types.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Employee Type</InputLabel>
              <Select
                value={employeeType}
                onChange={(event) => setEmployeeType(event.target.value)}
                label="Employee Type"
              >
                {employee_types.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={5}>
            <FormControl fullWidth size="small">
              <InputLabel>Deal Status</InputLabel>
              <Select
                multiple
                value={dealStatus}
                onChange={(event) => setDealStatus(event.target.value)}
                renderValue={(selected) => selected.join(', ')}
                label="Deal Status"
              >
                {deal_status.map((status) => (
                  <MenuItem key={status} value={status}>
                    <Checkbox checked={dealStatus.indexOf(status) > -1} />
                    <ListItemText primary={status} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default Filters;