import React from 'react';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { formatCurrency } from 'utils/formatCurrency';

const DetailPanelContent = ({ salesData, columnVisibilityModel, setColumnVisibilityModel }) => {
  const detailColumns = [
    { field: 'primaryEmployeeNo', headerName: 'Employee No.', width: 100 },
    { field: 'primaryEmployeeName', headerName: 'Employee', width: 150 },
    { field: 'ContractDate', headerName: 'Contract Date', width: 100 },
    { field: 'AccountingDate', headerName: 'Acct. Date', width: 100 },
    { field: 'DealCredit', headerName: 'Credit', width: 70 },
    { field: 'FiWipStatusCode', headerName: 'Status', width: 70 },
    { field: 'WAQNumber', headerName: 'Deal No', width: 75 },
    { field: 'StockNo', headerName: 'Stock No', width: 115 },
    { field: 'VIN', headerName: 'VIN', width: 175 },
    { field: 'FIDealType', headerName: 'Type', width: 75 },
    {
      field: 'Year',
      headerName: 'Year/Make/Model',
      valueGetter: (params) =>
        `${params.row.Year} ${params.row.MakeName} ${params.row.ModelName}`,
      minWidth: 200,
      flex: 1,
    },
    { field: 'Age', headerName: 'Age', width: 100 },
    {
      field: 'PayableFrontGross',
      headerName: 'Payable FEG',
      width: 115,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'PayableBackGross',
      headerName: 'Payable BEG',
      width: 115,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'ProductGross',
      headerName: 'Products',
      width: 115,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'FIIncome',
      headerName: 'Reserve',
      width: 115,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'BackGross',
      headerName: 'Back Gross',
      width: 115,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'TotalGross',
      headerName: 'Total Gross',
      width: 115,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'primaryEmployeeCommission',
      headerName: 'Commission',
      width: 115,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'CalculatedCommission',
      headerName: 'Audit',
      width: 115,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'BonusCommission',
      headerName: 'Potential Bonus',
      width: 115,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    { field: 'CRMSalesMgrName', headerName: 'Sales Mgr Name', width: 180 },
    { field: 'CRMFIMgrName', headerName: 'FI Mgr Name', width: 180 },
    { field: 'CRMSP1Name', headerName: 'SP1 Name', width: 180 },
    {
      field: 'CRMSaleCreditSP1',
      headerName: 'SP1 Credit',
      width: 100,
      valueFormatter: ({ value }) => {
        return value / 1000;
      },
    },
    { field: 'CRMSP2Name', headerName: 'SP2 Name', width: 180 },
    {
      field: 'CRMSaleCreditSP2',
      headerName: 'SP2 Credit',
      width: 100,
      valueFormatter: ({ value }) => {
        return value / 1000;
      },
    },
    { field: 'CRMSP3Name', headerName: 'SP3 Name', width: 180 },
    {
      field: 'CRMSaleCreditSP3',
      headerName: 'SP3 Credit',
      width: 100,
      valueFormatter: ({ value }) => {
        return value / 1000;
      },
    },
    {
      field: 'CRMClosingMgrName',
      headerName: 'Closing Mgr Name',
      width: 100,
    },
  ];

  return (
    <Box sx={{ width: '100%', padding: 2, backgroundColor: 'action.hover' }}>
      <DataGridPro
        sx={{
          backgroundColor: 'background.paper',
        }}
        getRowId={(row) => row._id}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        density="compact"
        rows={salesData || []}
        getRowClassName={(params) =>
          Math.abs(
            params.row.primaryEmployeeCommission -
              params.row.CalculatedCommission,
          ).toFixed(2) <= 0.011
            ? ''
            : 'rowHighlight'
        }
        columns={detailColumns}
        disableColumnFilter
        disableDensitySelector
        hideFooter
      />
    </Box>
  );
};

export default DetailPanelContent;
